import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import { PINOUT_VIEW, VEHICLE_VIEW, HARNESS_VIEW } from '../../constants'
import { selectNavigationTab } from '../../actions/tab-actions'
import { setSidebarState } from '../../actions/sidebar-actions'
import {
  isSelectedElementAHarness,
  isSelectedElementASplice,
} from '../../selectors'
import { selectElement } from '../../thunks/component'
import { MAIN_NAMESPACE } from '../../constants/index'

const propTypes = {
  currentTab: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  changeSidebarState: PropTypes.bool,
}

class Tab extends Component {
  componentDidMount() {
    if (this.props.currentTab === PINOUT_VIEW) {
      this.props.hideDtcSidebar()
    }
  }

  render() {
    const disableCondition1 =
      this.props.id === PINOUT_VIEW && this.props.harnessSelected
    const disableCondition2 =
      (this.props.id === PINOUT_VIEW || this.props.id === VEHICLE_VIEW) &&
      this.props.toggledOnDtc
    const disableConditionForSplice =
      this.props.id === PINOUT_VIEW && this.props.spliceSelected

    const btnClass = classNames('btn', {
      active: this.props.currentTab && this.props.currentTab === this.props.id,
      disabled:
        disableCondition1 || disableCondition2 || disableConditionForSplice,
    })

    return (
      <a onClick={this.onTabClick} className={btnClass}>
        {this.props.text}
      </a>
    )
  }

  onTabClick = () => {
    if (!this.props.currentTab || this.props.currentTab !== this.props.id) {
      this.props.onTabClick(this.props.id)
    }
  }
}

Tab.propTypes = propTypes

const mapStateToProps = (state, { namespace, tab }) => {
  const currentTab = get(state.tabState.currentTab, namespace)
  return {
    tab,
    currentTab,
    harnessSelected: isSelectedElementAHarness(state),
    spliceSelected: isSelectedElementASplice(state),
    toggledOnDtc: state.dtcState.toggledOnDtc,
    selectedElement: state.componentState.selectedElement,
  }
}

const mapDispatchToProps = (
  dispatch,
  { namespace, changeSidebarState = true },
) => {
  return {
    onTabClickInternal: (tab, selectedElement) => {
      if (changeSidebarState) {
        const showSidebar =
          (tab === HARNESS_VIEW || tab === VEHICLE_VIEW) &&
          namespace === MAIN_NAMESPACE
        dispatch(setSidebarState(showSidebar))
      }
      dispatch(selectNavigationTab(tab, namespace))

      if (tab === HARNESS_VIEW && selectedElement)
        setTimeout(() => {
          dispatch(selectElement(null, false))
          dispatch(selectElement(selectedElement, true))
        }, 10)
    },
    hideDtcSidebar: () => {
      dispatch(setSidebarState(false))
    },
  }
}
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onTabClick: (tab) =>
    dispatchProps.onTabClickInternal(tab, stateProps.selectedElement),
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Tab)
