import axiosWrapper, { InterceptorOptions } from './axios-wrapper'
import store from '../store'
import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { locale } from '../Locale'

import { createElementsAndNodeMapFromGraph } from '../helpers/utils'
import {
  setAllVehicleData,
  selectElementById,
} from '../actions/component-actions'
import { setError, clearError } from '../actions/error-actions'
import {
  ERROR_NO_GRAPH,
  INIT_SELECTED_COMPONENT_QUERY_PARAM,
  ROOT_CAUSE_QUERY_PARAM,
} from '../constants'
import {
  setFaultCategories,
  resetAppliedComponents,
  resetHistoryForComponent,
} from '../actions/logging-actions'
import { setHarnessLevels } from '../actions/harness-actions'

import { toggleOffDtc, unhighlightDtcs } from '../actions/dtc-actions'
import {
  getHistoryGroupedByComponents,
  updateCircuitState,
  getRepairHistory,
} from './logging-api'
import { apiGetTerminology } from './terminology'
import { push } from 'connected-react-router'
import { getVehicleTestIdByResultId } from './vehicle-tests-api'
import UrlService from '../services/url'
import { setDTCMemosByModules } from '../thunks/dtcMemo'

const api = axiosWrapper.create(
  {
    baseURL: '/api/',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
  InterceptorOptions.noCacheInterceptor,
)

const controllers = axiosWrapper.create(
  {
    baseURL: '/tracer/',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
  InterceptorOptions.noCacheInterceptor,
)

const cdn = axiosWrapper.create({ baseURL: '/tracer/cdn' })

const preLoadFetcher = axiosWrapper.create({
  baseURL: '/tracer/harness/',
})

// TODO Create api calls to harness resources
export function getHarnessDataFromPath(pathId) {
  if (pathId === 'no-result') {
    return
  }

  // Retrieved the cached JSON data from the path if it exists
  return controllers
    .get(`/vehicles/tests/${pathId}?datetime=${Date.now()}`, {
      params: {
        format: 'html',
      },
    })
    .then((response) => {
      const {
        faults,
        harness_levels,
        make_model_id,
        vehicle_test_result_id,
        vin,
      } = response.data
      store.dispatch(clearError())
      updateCircuitState(make_model_id, vin)
      store.dispatch(setFaultCategories(faults))
      store.dispatch(setHarnessLevels(harness_levels))
      store.dispatch(resetAppliedComponents())
      store.dispatch(resetHistoryForComponent())
      store.dispatch(toggleOffDtc())
      store.dispatch(unhighlightDtcs())
      getVehicleTestIdByResultId(vehicle_test_result_id)
      getRepairHistory(make_model_id, vin)
      getHistoryGroupedByComponents(make_model_id, vin, vehicle_test_result_id)
      formatResponse(response.data)
    })
    .catch((error) => {
      console.error('getHarnessDataFromPath::error', error, error.response)
    })
}

function postVehicleTestData(payload, authToken, url, open_root_cause = false) {
  const jsonPayloadAsString = JSON.stringify(payload)
  return api
    .post(url, jsonPayloadAsString, {
      headers: {
        Authorization: `bearer ${authToken}`,
      },
      params: {
        format: 'html',
      },
    })
    .then((response) => {
      const { cache_id } = response.data

      let url = `/tracer/${cache_id}`
      if (open_root_cause) {
        url = `${url}?${ROOT_CAUSE_QUERY_PARAM}`
      }

      store.dispatch(push(url)) // eslint-disable-line camelcase
    })
    .catch((error) => {
      console.error('postVehicleTestData::error', error, error.response)
    })
}

export async function getHarnessDataForDemo(payload = {}, authToken) {
  if (!payload.meta) {
    payload.meta = {}
  }
  payload.meta['isDemoPayload'] = true
  return postVehicleTestData(payload, authToken, '/v1/vehicletests')
}

export async function postEmptyVehicleTest(vin, authToken) {
  return postVehicleTestData({ vin }, authToken, '/v1/vehicletests/empty', true)
}

export function search(query, vin) {
  return preLoadFetcher
    .get(`/search?query=${query}&vin=${vin}`)
    .then((res) => res.data)
}

export function formatResponse(data) {
  createElementsAndComponents(data.priority_list, data.graph).then(
    async (obj) => {
      const codesFromElements = obj.elements
        .filter((e) => !!e.id)
        .map((e) => e.id)
      const codesFromNodes = obj.graphData.nodes
        .filter((n) => !!n.data.id)
        .map((n) => n.data.id)
      const codesFromEdges = obj.graphData.edges
        .filter((e) => !!e.data.harness)
        .map((e) => e.data.harness)
      const codes = [
        ...new Set(
          codesFromElements.concat(codesFromNodes).concat(codesFromEdges),
        ),
      ]
      // fetch terminology from external service
      const terms = await apiGetTerminology({
        makeModelId: data.make_model_id,
        language: locale,
        codes: codes,
      })
      obj.elements.forEach((e, idx) => {
        if (!!terms && !!terms[e.id] && !!terms[e.id]['description']) {
          // if we actually have terminology, overwrite our description with it
          obj.elements[idx].description = terms[e.id]['description']
        }
      })
      obj.graphData.nodes.forEach((n, idx) => {
        let key
        if (!!n.data && n.data.id) {
          key = n.data.id
        } else {
          return
        }
        if (!!terms && !!terms[key] && !!terms[key]['description']) {
          obj.graphData.nodes[idx].data.description = terms[key]['description']
        }
      })
      obj.graphData.edges.forEach((e, idx) => {
        let key
        if (!!e.data && !!e.data.harness) {
          key = e.data.harness
        } else {
          return
        }
        if (!!terms && !!terms[key] && !!terms[key]['description']) {
          obj.graphData.edges[idx].data.description = terms[key]['description']
        }
      })
      const modCompIdx = {}

      _.forEach(data.mod_to_comp_idx, (val, key) => {
        modCompIdx[key] = new Set(val)
      })

      const filteredModules = filterDtcs(data.modules)

      store.dispatch(
        setAllVehicleData({
          vehicleTestResultId: data.vehicle_test_result_id,
          elements: obj.elements,
          gateway: data.gateway,
          graphData: obj.graphData,
          makeModelId: data.make_model_id,
          modToCompIdx: modCompIdx,
          modules: filteredModules,
          sequenceNumber: data.sequence_number,
          svgEnabled: data.svg_enabled,
          vin: data.vin,
          dtcDependencies: data.dtc_dependencies,
        }),
      )
      store.dispatch(setDTCMemosByModules())

      const initSelectedElementId = UrlService.getQueryParam(
        INIT_SELECTED_COMPONENT_QUERY_PARAM,
      )
      if (initSelectedElementId) {
        store.dispatch(selectElementById(initSelectedElementId))
      }

      // Show error screen if there is no graph to render
      if (isEmpty(obj.elements)) {
        redirectToNoResult()
      }
    },
  )
}

function redirectToNoResult() {
  store.dispatch(setError(ERROR_NO_GRAPH))
}

/*
 * Collapses module DTCs with similar dtc code and start connector
 */
function filterDtcs(mods) {
  return _.map(mods, (m) => {
    m.dtcs = _(m.dtcs)
      .groupBy((d) => {
        return `${d.dtc_code}:${d.start_connector}`
      })
      .reduce((acc, dtcs) => {
        const head = _.head(dtcs)
        return [
          ...acc,
          {
            active: head.active,
            dtc_code: head.dtc_code,
            connectors: _.map(dtcs, (d) => d.connector),
            description: head.description,
            is_supported: head.is_supported,
            is_misconfigured: head.is_misconfigured,
            start_connector: head.start_connector,
            harness_pn: head.harness_pn,
            harness_description: head.harness_description,
            pins: _.reduce(
              dtcs,
              (acc, dtc) => {
                const pins = dtc.pins || []
                return [...acc, ...pins]
              },
              [],
            ),
          },
        ]
      }, [])
    return m
  })
}

/*
 * Returns a list of elements and a formatted check list
 */
async function createElementsAndComponents(checkList, graph) {
  const obj = await createElementsAndNodeMapFromGraph(graph)
  return {
    graphData: obj.elements,
    elements: createElementsList(checkList, obj.nodeMap, obj.edgeList),
  }
}

function createElementsList(checkList, nodeMap, edgeList) {
  let elements = []
  if (checkList instanceof Array && !_.isEmpty(checkList)) {
    elements = _(checkList)
      .map((n) => {
        return nodeMap.get(n)
      })
      .reject((o) => o === undefined)
      .value()
    elements = elements.concat(edgeList)
  }
  return elements
}

export async function getDeviceList(vin, componentId) {
  const response = await controllers.get(
    `vehicles/${vin}/devices?componentId=${componentId}`,
  )
  return response.data
}
