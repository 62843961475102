import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { unsetModal } from '../../actions/modal-actions'
import ComponentStateModal from './index.js'
import { clearInlineComponents } from '../../actions/harness-actions'
import {
  getDtcCodesFromHighlightedDtcs,
  getHistoryForSelectedModalComponent,
} from '../../selectors'
import { getElementName } from '../../helpers/element'
import { logComponentProblem } from '../../thunks/component'

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    makeModelId: state.componentState.makeModelId,
    vin: state.componentState.vin,
    component: state.componentState.selectedElement,
    graphEdges: state.componentState.graphData.edges,
    selectedComponent: state.modalState.component,
    historyForComponent: getHistoryForSelectedModalComponent(state),
    dtcs: getDtcCodesFromHighlightedDtcs(state),
    faults: state.loggingState.faults.component,
    title: getElementName(state.componentState.selectedElement),
    placeHolder: (
      <FormattedMessage id="tracer.logFailure.typePlaceholderComponent" />
    ),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClose: () => {
      dispatch(clearInlineComponents())
      dispatch(unsetModal())
    },
    _onSubmit: (options) => {
      dispatch(logComponentProblem(options))
    },
  }
}
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, // optional
    ...dispatchProps, // optional
    onSubmit: (args) => {
      dispatchProps._onSubmit({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
        isProblem: true,
        dtcs: stateProps.dtcs,
        componentId: stateProps.component.id,
      })
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ComponentStateModal)
