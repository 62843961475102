import { useQuery } from 'react-query'
import { search, getDeviceList } from '../api/harness-api'
import { locale } from '../Locale'
import { apiGetTerminology } from '../api/terminology'

const HARNESS_SEARCH_KEY = 'search-harness'

export const useHarnessSearch = ({ searchString, vin }) => {
  const { status, data, error, isFetching, isLoading, isError, isSuccess } =
    useQuery(
      [HARNESS_SEARCH_KEY, searchString, vin],
      async () => {
        const results = await search(searchString, vin)
        if (results && results.data) {
          const codes = results.data.map((d) => d.harness)
          const terms = await apiGetTerminology({
            vin,
            codes,
            language: locale,
          })
          results.data.forEach((d, idx) => {
            const key = d.harness
            if (!!key && !!terms[key] && !!terms[key]['description']) {
              results.data[idx]['description'] = terms[key]['description']
            }
          })
        }
        return results
      },
      {
        refetchOnWindowFocus: false,
        enabled: !!searchString,
      },
    )

  return {
    status,
    harnesses: data && data.data,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
  }
}

export const useDeviceList = ({ vin, componentId }) => {
  const {
    status,
    data: devicelist,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
  } = useQuery(
    ['fetch-device-list', vin, componentId],
    async () => {
      const devicelist = await getDeviceList(vin, componentId)
      return devicelist
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!componentId && !!vin,
    },
  )
  return {
    status,
    devicelist,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
  }
}
