import { useLocation } from 'react-router-dom'
const MODE = 'mode'
const EMBEDDED_MODE = 'embedded'

function isEmbeddedMode(searchParams) {
  const params = new URLSearchParams(searchParams)
  return params.get(MODE) === EMBEDDED_MODE
}

function getNavigatedFromKey(searchParams) {
  const params = new URLSearchParams(searchParams)
  return params.get('navigatedFrom')
}

function useFunctionalDiagrams() {
  const location = useLocation()
  return location.pathname.includes('functional-diagrams')
}

function useIsBlockDiagramPreview() {
  const location = useLocation()
  return location.pathname.includes('block-diagram-preview')
}

export {
  isEmbeddedMode,
  getNavigatedFromKey,
  useFunctionalDiagrams,
  useIsBlockDiagramPreview,
  MODE,
  EMBEDDED_MODE,
}
